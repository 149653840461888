import React from 'react'
import { Link } from "gatsby"

import SEO from "../../../components/seo"
// import { Helmet } from 'react-helmet'
import TwoColumn from "../../../components/two-column"
import Layout from "../../../components/layout"
import CustomerJourney from '../../../components/customer-journey'
import List from "../../../components/compare-list"
import Columnar from '../../../components/columnar'
import SingleContent from "../../../components/single-column"

const bannerContent = {
  title: "Create a membership site and accept recurring payments with the Billsby WordPress plugin",
  description: [
    {
      text: <>The <strong>Billsby WordPress plugin</strong> lets you add subscription billing to your site and handles all the technical stuff - checkout and account management pages, recurring billing, content locking and compliance. </>
    }
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up for free',
      isExternal: true,
    },
    {
        url: 'https://wordpress.org/plugins/billsby/',
        buttonColor: 'white',
        buttonText: 'Download at WordPress.org',
        isExternal: true,
    }
  ],
  isBanner: true,
  sectionZapier: true,
  image: require('../../../images/billsby-member-site.svg'),
  svg: true,
  imageName: 'member-site svg',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right',
}

const journey1 = {
    title: "Checkout and account management built into your WordPress site.",
    list: [
        {
            title: 'Everything happens on your website',
            description: <>Customers can subscribe, create their account and even manage and update their details without ever leaving your website. You'll be in control of the whole experience and it'll all be in your brand thanks to our <Link to="/product/subscribers/account-management">checkout and account management modules</Link>.</>,
        },
        {
            title: 'Perfectly integrated with WordPress ',
            description: <>Our plugin meets all of the WordPress best practices and security standards, and is released under the GPL license and available on the WordPress plugin directory - so you know that you can be confident in using our plugin to manage your subscriptions.</>,
        },
    ],
    image: require('../../../images/billsby-cart.svg'),
    svg: true,
    imageName: 'billsby-cart'
}

const journey2 = {
    title: "Synchronize customer data between WordPress and Billsby.",
    list: [
        {
            title: 'Keep everything in sync ',
            description: <>We'll synchronize your customers name and email address between WordPress and Billsby - so if you change their details in one place, it'll be changed everywhere. We'll also synchronize their plan and feature tags into WordPress for you, so you can see them in one place.</>,
        },
        {
            title: 'Automatically handle non-payment ',
            description: <>Thanks to our <Link to="/product/subscribers/dunning-retention">dunning engine</Link> we'll automatically handle cancelling subscriptions in WordPress when your customers don't pay their bills, so they won't have access to content and services that they haven't paid for. It's quick and simple.</>,
        },
    ],
    align: 'right',
    image: require('../../../images/billsby-customer-data.svg'),
    svg: true,
    imageName: 'billsby-customer-data'
}

const journey3 = {
    title: "Lock whole pages, or just specific sections ",
    list: [
        {
            title: 'Keep everything in sync ',
            description: <>It's entirely up to you how locked down you make your membership site. You can lock whole pages away behind the paywall, or just restrict access to certain sections of pages and posts from your WordPress website using our simple-to-use shortcodes.</>,
        },
        {
            title: 'Create unlimited complex access conditions ',
            description: <>Because access control is powered by <Link to="/product/integrations/feature-tags">feature tags</Link> you can create really complex access control rules if you want - only showing certain content to users with multiple feature tags, or showing content to users in the absence of feature tags. You've got complete control.</>,
        },
    ],
    image: require('../../../images/billsby-vault.svg'),
    svg: true,
    imageName: 'billsby-vault'
}

const listContent = {
    bgColor: "blue",
    content: [
        {
            title: "Safe and secure with full PCI-DDS compliance as standard",
            image: require('../../../images/billsby-payment.svg'),
            svg: true,
            list: [
                {
                    text: "If you create your own subscription management,or download a plugin that's doing the hard work on your server rather than externally,it might be harder for you to comply with the laws governing transactions-like PCI-DSS or local tax rules.Billsby handles all that hard stuff for you,so you can rest assured that your customers will have a great experience-that's also completely legally compliant and keeps you safe."
                },
            ]
        },
    ]
}
  

const columnarContent = [
    {
        title: "Get up and running in a matter of minutes.",
        list: [
            {
                description: "We've built the Billsby WordPress plugin with simple setup front of mind. Just follow the steps in your WordPress control panel and you can be up and running with subscription billing and account management in a matter of minutes - no complex implementation delays.",
            },
        ]
    },
    {
        title: "Spend less time integrating systems and services.",
        list: [
            {
                description: "You'll never have to worry about payment gateway integrations again - and your business will even have the flexibility to switch gateways at any time with no impact on you or your development cycle - it only takes a click to immediately start billing through an alternate provider.",
            },
        ]
    }
]

const singleContent =   {
    title: "Explore away, there's no credit card required",
    text: "We know that sometimes the best way to evaluate a solution is to try it for yourself, so we've made sure that signing up doesn't require a credit card, and our onboarding team are standing by to help with any questions.",
    button: [
      {
        buttonColor: "orange",
        url: "https://app.billsby.com/registration",
        btnText: "Sign up for free",
      },
      {
        url: 'https://wordpress.org/plugins/billsby/',
        buttonColor: 'black',
        btnText: 'Download at WordPress.org',
        isExternal: true,
      },
    ]
  }

const zapier = () => {
  return (
    <Layout className="wordpress">
      <SEO 
        title="WordPress | Billsby | Powerful, customizable subscription billing software" 
        description="Checkout and account management built into your WordPress site." 
        url="https://www.billsby.com/product/integrations/wordpress"
      />

      <TwoColumn content={bannerContent}/>
      <CustomerJourney content={journey1} />
      <CustomerJourney content={journey2} />
      <CustomerJourney content={journey3} />
      <List compare={listContent}/>
      <Columnar content={columnarContent} />
      <SingleContent content={singleContent} />
    </Layout>
  )
}

export default zapier
